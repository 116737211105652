const TRANSLATIONS_JP = {
  global: {
    noData: 'No data',
    yes: 'はい',
    no: 'いいえ',
  },
  login: {
    login: 'サインイン',
    email: 'メールアドレス',
    password: 'パスワード',
  },
  menuBar: {
    dashboard: 'ダッシュボード',
    bookings: '予約',
    sales: '販売',
    providerEarnings: 'プロバイダーの収益',
    providerPayout: 'プロバイダーの支払い',
    providers: 'サービスプロバイダー',
    customers: 'お客様',
    services: 'サービス',
    support: 'サポート',
    userReport: 'ユーザーレポート',
    systemUsers: 'システムユーザー',
    users: 'ユーザー',
    roles: '役割',
    activityLogs: 'アクティビティログ',
    settings: '設定',
  },
  actionButton: {
    addCoach: 'コーチを追加',
    uploadAndContinue: 'アップロードして続行',
    addFile: 'ファイルを追加',
    createAccounts: 'アカウントを作成する',
    replaceFile: 'ファイルを置き換える',
    cancel: 'キャンセル',
    ok: 'わかった',
    createAccount: 'アカウントを作成する',
    changePassword: 'パスワードを変更する',
    change: '変化する',
    deactivate: '非アクティブ化',
    active: 'アクティブ',
    delete: '消去',
    prev: '前へ',
    next: '次',
    showToday: '今日見せて',
    showThisWeek: '今週見せて',
    showThisMonth: '今月表示',
    showThisCycle: 'このサイクルを表示',
    view: 'ビュー',
  },
  validation: {
    required: '必須項目です',
    invalidEmail: 'このメールアドレスは無効です',
    invalidWeb: '無効なURLです',
    INVALID: '無効です',
    confirmPassNotMatch: 'The confirm password does not match',
    passRule: 'アルファベットの大文字小文字を含む英数字8文字以上で設定してください',
    registeredEmail: 'このメールアドレスは登録済みです',
    alphabetRequired: 'Name may include only alphabetical characters.',
    maxCharacters: 'Max length is 10 characters',
  },
  example: {
    email: 'メール',
    id: 'ID',
  },
  tableForm: {
    search: '検索',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: '結果のエクスポート（CSV）',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
  },
  profile: {
    fullName: '氏名',
    accountStatus: 'アカウントのステータス',
    lastLogin: '前回のログイン',
    phoneticName: 'フリガナ',
    email: 'Eメール',
    emailAddress: 'メールアドレス',
    birthday: '誕生日',
    phone: '電話番号',
    phoneNumber: '電話番号',
    lastName: '姓',
    firstName: '名',
    phoneticLastName: 'フリガナ(姓)',
    phoneticFirstName: 'フリガナ(名)',
    displayName: '表示名',
    profilePicture: 'プロフィール写真',
    gender: '性別',
    male: '男',
    female: '女性',
    unanswered: '未回答',
    registerDate: '登録日',
    region: '地域',
    prefecture: '都道府県',
    zipCode: '郵便番号',
    businessImages: 'ビジネス画像',
    profileText: 'プロフィール文章',
    businessName: '商号',
    businessNumber: '事業番号',
    addressLine1: '番地',
    municipality: '市区町村',
    addressLine2: '建物名等',
    addressPrivacySettings: 'サロン住所公開設定',
    addressPrivacy: 'プライバシーに対処する',
    mapLocation: '地図の場所',
    accountHolder: '銀行口座（口座名）',
    accountNumber: '銀行口座（口座番号）',
    accountType: '口座の種類',
    bankBookImage: '通帳画像',
    financialInstitution: '金融機関',
    financialInstitutionName: '銀行口座（金融機関名）',
    bankCode: '金融機関コード',
    branchName: '銀行口座（支店名）',
    branchCode: '支店コード',
    username: 'ユーザー名',
    password: 'パスワード',
    role: '役割',
    newPassword: '新しいパスワード',
    permissionBooking: '予約',
    permissionProvider: 'プロバイダー管理',
    permissionCustomer: '顧客管理',
    permissionService: 'サービス管理',
    description: '説明',
    edit: 'プロファイル編集',
  },
  detail: {
    detail: '詳細',
    paymentType: '払いの種類',
    paymentID: '支払い ID',
    customerName: '顧客名',
    totalChargedToCard: 'カードに請求された合計',
    cardEndingIn: 'で終わるカード',
    chargedOn: '充電済み',
    menuItem: 'メニュー項目',
    amount: '額',
    totalAmount: '合計金額',
    subtotal: '小計',
    discount: '割引',
    lastUpdated: '最終更新',
    description: '説明',
    servicePhoto: 'サービス写真',
    displayOrder: '表示順',
  },
  action: {
    changeStatus: 'ステータスを変更する',
    cancel: 'キャンセル',
    duplicate: '複製',
    viewDetails: '詳細を見る',
  },
  status: {
    pending: '保留中',
    confirmed: '確認済み',
    cancelled: 'キャンセル',
    complete: '完了',
    deleted: '削除されました',
    active: 'アクティブ',
    disabled: '無効',
  },
  title: {
    serviceProviders: 'サービスプロバイダー',
    profile: 'プロフィール',
    booking: '予約',
    services: 'サービス',
    schedule: '予定',
    sales: '販売',
    reviews: 'レビュー',
    basicInformation: '基本情報',
    businessInformation: 'ビジネス情報',
    bankInformation: '銀行情報',
    customers: '顧客',
    genderatedContent: '性別コンテンツ',
    bookingInformation: '予約情報',
    paymentInformation: '支払情報',
    statusHistory: 'ステータス履歴',
    requested: 'リクエスト済み',
    confirmation: '確認',
    createService: 'サービスを作成する',
    editCategory: 'カテゴリを編集',
    usernameAndPassword: 'ユーザー名とパスワード',
    systemAccount: 'システムアカウント',
    role: '役割',
    changePassword: 'パスワードを変更する',
    deactivate: '非アクティブ化',
    active: 'アクティブ',
    delete: '消去',
    nameAndDescription: '名前と説明',
    permission: '許可',
    earnings: '収益',
    currentPayoutCycleEarnings: '現在の支払いサイクルの収益',
    historyEarnings: '過去の収益',
    earningDetail: '収益の詳細',
    transactions: '取引',
  },
  description: {
    statusHistoryRequested: 'お客様が予約をリクエストしました',
    statusHistoryConfirmation: 'プロバイダーの確認を待っています',
    statusHistoryComplete: '顧客はサービスを受けました',
    changePassword: 'このアカウントのパスワードを変更してください。',
    deactivateAccount: 'アカウントは無効になり、ユーザーはアクセスできなくなります。',
    activeAccount: 'アカウントがアクティブになり、ユーザーがアクセスできるようになります。',
    deleteAccount: 'アカウントは削除されます。',
    activeRole: 'ロールがアクティブになり、ユーザーがアクセスできるようになります。',
    deactivateRole: 'ロールは非アクティブ化され、ユーザーはアクセスできなくなります。',
    deleteRole: '役割は削除されます。',
  },
  tableColumn: {
    view: 'ビュー',
    edit: '編集',
    active: 'アクティブ',
    deactive: '非アクティブ化',
    delete: '消去',
  },
  columnTitle: {
    numberId: 'ID #',
    avatar: 'アバター',
    fullName: 'フルネーム',
    email: 'メールアドレス',
    phoneNumber: '電話番号',
    registerDate: '登録日',
    status: 'ステータス',
    action: 'アクション',
    bookingId: '予約 ID',
    lastUpdate: '最後の更新',
    bookingDate: '予約 日',
    provider: 'プロバイダー',
    customer: 'お客様',
    total: '合計',
    categoryName: 'カテゴリ名',
    serviceName: 'サービス名',
    duration: '間隔',
    price: '価格',
    category: 'カテゴリー',
    tags: 'タグ',
    providerName: 'プロバイダー',
    datePayout: '日にち',
    fromDate: '日付から',
    toDate: '現在まで',
    totalGross: '総収入',
    totalFee: '合計料金',
    totalNet: 'ネット合計',
    name: '名前',
    role: '役割',
    description: '説明',
    activityTime: '時間',
    message: 'メッセージ',
    triggeredBy: '引き起こされた',
    earningGross: '総収入',
    earningNet: '収益ネット',
    from: 'から',
    to: 'に',
    date: '日にち',
    transactionId: 'トランザクションID',
    transactionDate: '日にち',
    amount: '額',
    amountNet: '純額',
  },
  extraContent: {
    importCSV: 'インポート（CSV）',
    exportAllCSV: 'すべてエクスポート（CSV）',
    createUser: 'ユーザーを作成',
    createBooking: '予約を作成する',
    createCategory: 'カテゴリを作成する',
    addService: 'サービスを追加',
    createRole: '役割を作成する',
  },
  placeholder: {
    searchDefault: 'ユーザー名、ID、登録済みメールを検索',
    searchBooking: '予約 ID、顧客、プロバイダーを検索',
    searchService: '検索サービス名',
    searchProviderEarning: 'プロバイダーの電子メール、ID、ユーザー名、電話で検索',
    searchPayout: 'ID、名前で支払いを検索',
  },
  type: {
    protect: '守る',
    public: '公衆',
    saving: '保存',
    standard: '標準',
  },
  importCSVModal: {
    title: 'CSVのインポートによるプロバイダーの作成',
    download: 'ダウンロード',
    sampleCSVTemplate: 'サンプルCSVテンプレート',
    seeExample: '例を参照してください。',
    dragDropCSV: 'ここにCSVファイルをドラッグアンドドロップします',
    overwriteDuplicateEmail: 'メールアドレスが重複しているアカウントを上書きします。',
    addedFile: '追加されたファイル',
    importedCSVNotification: `複数のプロバイダーアカウントを作成します。プレビューして、コンテンツが正しく表示されることを確認してください。
    そうでない場合は、ここで調整できます。`,
    youWillImport: 'インポートします',
    numberAccount: 'アカウント',
    withTotal: '合計などでファイルの詳細に関するテキストをコピーします。',
    importingDataNotificationTitle: 'データベース内のプロバイダーリストへの現在のインポートデータ',
    importingDataNotificationContent: `これは、完了するのにしばらく時間がかかる可能性があります。よろしければ、
    このダイアログボックスを閉じて、作業を続行できます。`,
    closeImportCSVTitle: 'CSVポップアップのインポートを閉じますか？',
    closeImportCSVContent: 'CSVのインポートをキャンセルしますか。',
  },
  providerConfirmModal: {
    disabled: 'このプロバイダーアカウントを無効にしますか？',
    active: 'このプロバイダーアカウントを有効にしますか？',
    delete: 'このプロバイダーアカウントを削除しますか？',
  },
  customerConfirmModal: {
    disabled: 'この顧客アカウントを無効にしますか？',
    active: 'この顧客アカウントを有効にしますか？',
    delete: 'この顧客アカウントを削除しますか？',
  },
  dateViewMode: {
    today: '今日',
    thisWeek: '今週',
    thisMonth: '今月',
    thisCycle: 'このサイクル',
    customDate: 'カスタム日付',
    day: '日',
    week: '週',
    month: '月',
    payoutCycle: '支払いサイクル',
    custom: 'カスタム',
  },
  changePasswordModal: {
    title: 'あなたのパスワードをリセット？',
    content: 'パスワードをリセットしますか？ パスワードのリセットリンクをメールで送信します。',
    contentForUser:
      'このユーザーのパスワードをリセットしますか？ このユーザーのメールアドレスにパスワードのリセットリンクを送信します。',
  },
  notification: {
    notificationTitleText: '通知',
    notificationMarkReadText: 'すべてを既読にする',
    notificationLoadedText: 'あなたはそれをすべて見ました',
    notificationLoadingText: '読み込み中...',
    notificationNoDataText: 'データなし',
  },
  toastrMessage: {
    createSuccess: '正常に作成されました',
    updateSuccess: '更新成功',
    deleteSuccess: '正常に削除',
  },
  changeStatusServiceModal: {
    titleActive: 'このサービスをアクティブにしますか？',
    titleDeactive: 'このサービスを無効にしますか？',
    contentActive: 'サービスがアクティブになります',
    contentDeactive: 'サービスは非アクティブになります',
  },
  deleteServiceModal: {
    title: 'このサービスを削除しますか？',
    content: 'このサービスは削除されます',
  },
  common: {
    upload: 'アップロード',
    createSystemAccount: 'システムアカウントを作成する',
    editSystemAccount: 'システムアカウントを編集する',
    createRole: '役割の作成',

    booking: '予約',
    bookings: '予約',
    order: '注文',
    orders: '注文',
    search: '探す',
    importCSV: 'インポート (CSV)',
    exportCSV: 'エクスポート (CSV)',
    exportAllCSV: 'すべてエクスポート (CSV)',
    exportResultsCSV: '結果のエクスポート (CSV)',
    lastUpdated: '最終更新',
    provider: 'プロバイダ',
    customer: 'お客様',
    customers: '顧客',
    total: '合計',
    status: 'ステータス',
    action: 'アクション',
    view: '意見',
    pending: '保留中',
    cancelled: 'キャンセル',
    confirmed: '確認済み',
    complete: '完了',
    changeStatus: 'ステータスを変更する',
    delete: '消去',
    deleted: '消去',
    cancel: 'キャンセル',
    duplicate: '複製',
    viewDetails: '詳細を見る',
    edit: '編集',
    menuItem: 'メニュー項目',
    duration: '間隔',
    amount: '額',
    totalAmount: '合計金額',
    subtotal: '小計',
    discount: '割引',
    statusHistory: 'ステータス履歴',
    requested: '要求された',
    confirmation: '確認',
    services: 'サービス',
    create: '作成',
    totalChargedToCard: 'カードに請求された合計',
    cardEndingIn: 'で終わるカード',
    chargedOn: 'に請求',
    min: '分',
    confirm: '確認',
    ruSure: '本気ですか？',
    item: 'アイテム',
    items: 'アイテム',
    displayName: '表示名',
    name: '名前',
    emailAddress: 'メールアドレス',
    phone: '電話',
    phoneNumber: '電話番号',
    lastLogin: '最終ログイン',
    active: 'アクティブ',
    disabled: '無効',
    fullName: 'フルネーム',
    tags: 'タグ',
    profile: 'プロフィール',
    generatedContent: '生成されたコンテンツ',
    reviews: 'レビュー',
    basicInfo: '基本情報',
    phoneticName: 'ふりがな',
    email: 'Eメール',
    birthday: '誕生日',
    gender: '性別',
    registerDate: '登録日',
    region: '領域',
    prefecture: '県',
    zipCode: '郵便番号',
    lastName: '苗字',
    firstName: 'ファーストネーム',
    phoneticLastName: '姓のふりがな',
    phoneticFirstName: '名のふりがな',
    profilePicture: 'プロフィールの写真',
    optional: 'オプション',
    male: '男',
    female: '女性',
    unanswered: '未回答',
    disable: '無効にする',
    enable: '有効',
    editProfile: 'プロファイル編集',
    serviceProviders: 'サービスプロバイダー',
    schedule: 'スケジュール',
    earnings: '収益',
    public: '公衆',
    protect: '守る',
    standard: '標準',
    saving: '保存',
    profileText: 'プロフィールテキスト',
    city: '街',
    mapLocation: '地図の場所',
    financialInstitution: '金融機関',
    financialInstitutionName: '金融機関名',
    date: '日にち',
    from: 'から',
    to: 'に',
    grossAmount: '総額',
    totalFee: '合計料金',
    netAmount: '正味金額',
    description: '説明',
    price: '価格',
    category: 'カテゴリー',
    displayOrder: '表示順',
    transactions: '取引',
    time: '時間',
    message: 'メッセージ',
    triggeredBy: '引き起こされた',
    operator: 'オペレーター',
    admin: '管理者',
    user: 'ユーザー',
    activityLogs: 'アクティビティ ログ',
    role: 'ロール',
    roles: 'ロール',
    activate: '活性化',
    deactivate: '無効にする',
    prohibited: '禁止',
    all: '全て',
    nameAndDescription: '名前と説明',
    permissions: '権限',
    systemAccounts: 'システム アカウント',
    changePass: 'パスワードを変更する',
    change: '変化する',
    username: 'ユーザー名',
    password: 'パスワード',
    usernameAndPassword: 'ユーザー名とパスワード',
    users: 'ユーザー',
    systemUsers: 'システム ユーザー',
    categories: 'カテゴリー',
    uploadAndContinue: 'アップロードして続行',
    or: 'または',
    totalGross: '総収入',
    totalNet: '合計ネット',
    providerEarnings: 'プロバイダの収益',
    day: '日',
    week: '週',
    month: '月',
    payoutCycle: '支払いサイクル',
    custom: 'カスタム',
    today: '今日',
    prev: '前へ',
    next: '次',
    customDate: 'カスタム日付',
    earning: '収入を得る',
    commissionFee: '手数料',
    choice: '選択',
    staff: 'スタッフ',
    createNewAccount: '新しいアカウントを作成する',
    logoutText: 'ログアウト',
    changePasswordText: 'パスワードを変更する',
  },
  booking: {
    create: '予約を作成',
    searchPlaceholder: '予約ID、顧客、プロバイダーを検索',
    ID: '予約ID',
    date: '予約日',
    info: '予約情報',
    statusHistoryRequested: 'お客様が予約をリクエストしました',
    statusHistoryConfirmation: 'プロバイダの確認待ち',
    statusHistoryComplete: 'お客様が受けたサービス',
  },
  payment: {
    info: '支払情報',
    type: '払いの種類',
    ID: '支払いID',
    cycle: '支払サイクル',
  },
  customer: {
    management: '顧客管理',
    name: '顧客名',
    searchPlaceholder: 'ユーザー名、ID、ユーザー名、登録メールアドレスを検索',
    disableConfirmContent: 'この顧客アカウントを無効にしますか？',
    enableConfirmContent: 'この顧客アカウントを有効にしますか？',
    deleteConfirmContent: 'この顧客アカウントを削除しますか？',
  },
  provider: {
    management: 'プロバイダー管理',
    disableConfirmContent: 'このプロバイダー アカウントを無効にしますか?',
    enableConfirmContent: 'このプロバイダー アカウントを有効にしますか?',
    deleteConfirmContent: 'このプロバイダ アカウントを削除しますか?',
    payout: 'プロバイダーへの支払い',
    address: 'プロバイダーの住所',
  },
  service: {
    management: 'サービス管理',
    add: 'サービスを追加',
    create: 'サービスの作成',
    edit: 'サービスを編集',
    name: 'サービス名',
    photo: 'サービス写真',
    activateModalTitle: 'このサービスを有効にしますか?',
    activateModalContent: 'サービスが有効になります',
    deactivateModalTitle: 'このサービスを無効にしますか？',
    deactivateModalContent: 'サービスが無効になります',
    deleteModalTitle: 'このサービスを削除しますか?',
    deleteModalContent: 'このサービスを削除しますか?',
  },
  user: {
    create: 'ユーザーを作成',
    changePassCardContent: 'このアカウントのパスワードを変更します。',
    changePassModalTitle: 'あなたのパスワードをリセット？',
    changePassModalContent:
      'パスワードをリセットしますか?パスワードのリセットリンクをメールに送信します。',
    activateCardContent: 'アカウントがアクティブになり、アクセスできるようになります。',
    activateModalTitle: 'このアカウントを有効にしますか?',
    activateModalContent: 'このロールを有効にしますか?',
    deactivateCardContent: 'アカウントは無効になり、アクセスできなくなります。',
    deactivateModalTitle: 'このアカウントを無効にしますか？',
    deactivateModalContent: 'このロールを無効にしますか?',
    deleteCardContent: 'ロールが削除されます。',
    deleteModalTitle: 'このアカウントを削除しますか?',
    deleteModalContent: 'このアカウントを削除しますか?',
  },
  account: {
    status: 'アカウントのステータス',
    createNew: '新しいアカウントを作成する',
    holder: '口座名義人',
    number: '口座番号',
    type: '口座の種類',
  },
  business: {
    info: 'ビジネス情報',
    images: 'ビジネスイメージ',
    name: '商号',
    number: '事業所番号',
  },
  address: {
    line1: '住所1',
    line2: '住所2',
    privacySettings: 'アドレスのプライバシー設定',
  },
  bank: {
    info: '銀行情報',
    code: '金融機関コード',
    bookImage: '通帳イメージ',
    name: '銀行名',
    number: 'バンク番号',
    username: '銀行のユーザー名',
  },
  branch: {
    name: '支店名',
    code: '支店コード',
  },
  transaction: {
    ID: '取引ID',
  },
  earning: {
    details: '収益の詳細',
  },
  earnings: {
    gross: '粗利益',
    net: '純利益',
    currentPayoutCycle: '現在の支払いサイクルの収益',
    history: '過去の収益',
  },
  edit: {
    basicInfo: '基本情報の編集',
    businessInfo: 'ビジネス情報の編集',
    bankInfo: '銀行情報の編集',
    systemAccount: 'システム アカウントの編集',
    category: 'カテゴリを編集',
  },
  create: {
    provider: 'プロバイダーの作成',
    providerViaImportCSV: 'インポート CSV によるプロバイダーの作成',
    customerViaImportCSV: 'インポート CSV による顧客の作成',
    role: 'ロールを作成',
    account: 'アカウントを作成する',
    accounts: 'アカウントを作成する',
    systemAccount: 'システム アカウントの作成',
    category: 'カテゴリを作成',
    viaImportCSV: {
      downloadSampleCSVTemplate:
        '<1>サンプルの CSV テンプレート</1>をダウンロードして、例を確認してください。',
      dragDropCSVFile: 'CSVファイルをここにドラッグドロップ',
      overwriteAccountDuplicateEmail: '重複する電子メール アドレスを持つアカウントを上書きします。',
      uploadedContent:
        '<1>{{total}}個のアカウント</1>をインポートし、合計などなど、ファイルの詳細に関するテキストをここにコピーします。',
      creatingContent:
        'これには、完了するまでに時間がかかる場合があります。必要に応じて、このダイアログ ボックスを閉じて作業を続けることができます。',
      provider: {
        uploadedTitle:
          '複数のプロバイダー アカウントを作成しています。プレビューして、コンテンツが正しく表示されることを確認してください。そうでない場合は、ここで調整できます。',
        creatingTitle: 'データベース内のプロバイダー リストに現在データをインポートしています',
      },
      customer: {
        uploadedTitle:
          '複数の顧客アカウントを作成しています。プレビューして、内容が正しく表示されることを確認してください。そうでない場合は、ここで調整できます。',
        creatingTitle: 'データベースの顧客リストに現在データをインポートしています',
      },
    },
  },
  role: {
    name: 'ロール名',
    description: '役割の説明',
    activateCardContent: 'ロールがアクティブになり、ユーザーがアクセスできるようになります。',
    activateModalTitle: 'このロールを有効にしますか?',
    activateModalContent: 'このロールを有効にしますか?',
    deactivateCardContent: 'ロールは非アクティブ化され、ユーザーはアクセスできなくなります。',
    deactivateModalTitle: 'この役割を無効にしますか?',
    deactivateModalContent: 'このロールを無効にしますか?',
    deleteCardContent: 'ロールが削除されます。',
    deleteModalTitle: 'このロールを削除しますか?',
    deleteModalContent: 'このロールを削除しますか?',
    administrator: '管理者',
    custom: 'カスタム',
    provider: 'プロバイダー',
  },
  category: {
    name: '種別名',
  },
  add: {
    category: 'カテゴリを追加',
    file: 'ファイルを追加',
    service: 'サービスを追加',
  },
  replace: {
    file: 'ファイルを置換',
  },
  show: {
    today: '今日表示',
    thisWeek: '今週上映',
    thisMonth: '今月表示',
    thisCycle: 'このサイクルを表示',
  },
  this: {
    week: '今週',
    month: '今月',
    cycle: 'このサイクル',
  },
  payout: {
    date: '支払い日',
    cycle: '支払いサイクル',
    payout: '支払い',
    payoutDate: '支払い日',
    earning: '収入を得る',
    commissionFee: '手数料',
    transferFee: '転送料',
    transferTotal: '合計転送',
    providerAddress: 'プロバイダーアドレス',
    staffNumber: 'スタッフ番号',
    chargedBooking: '有料予約',
    bankName: '銀行名',
    bankNumber: '銀行番号',
    bankUserName: '銀行のユーザー名',
    staff: 'スタッフ',
    chargedDate: '課金日',
  },
  transfer: {
    fee: '転送料',
    total: '転送合計',
  },
  mark: {
    asCompleted: '完了としてマーク',
  },
  charged: {
    date: '請求日',
    booking: '有料予約',
  },
  staff: {
    number: 'スタッフ番号',
  },
};

export default TRANSLATIONS_JP;
