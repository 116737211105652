import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as LayoutPage, NotificationItem, NotificationProps } from 'admin-console-library';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n';

import { useAppDispatch, useAppSelector } from 'store';
import { changePasswordRequest } from 'core/providers/AuthProvider/slice';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import { AUTH0_CLIENT_ID, AUTH0_CONNECTION } from 'core/utils/config';
import { GET_ACCESS_TOKEN_MINUTE_MS, langOptions } from 'core/utils/constants';
import Helper from 'core/utils/helpers';
import { indexRoutes, Route } from 'core/routes';
import { changeLanguage } from 'core/providers/LangProvider/slice';
import { useFetchListNotChangeSearchParam } from 'core/hooks';
import {
  getNotificationListRequest,
  markReadAllRequest,
  markReadItemRequest,
  resetNotificationList,
} from 'providers/NotificationProvider/slice';

const LayoutContainer = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { logout, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { t } = useTranslation('alert');
  const history = useHistory();
  const { location } = history;
  const lang = useAppSelector((state) => state.lang);

  // Notification -----------------------------

  const { list, params, total, loading, loadList } = useFetchListNotChangeSearchParam(
    getNotificationListRequest,
    {
      providerName: 'notification',
    },
  );
  const isShowDot = useAppSelector((state) => state.notification.isShowDot);

  const { page } = params;

  const loadDataNotification = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(resetNotificationList());
      loadList({ page: 1 });
    }
  };

  const loadMoreNotification = () => {
    loadList({ page: page + 1 });
  };

  const markReadAll = () => {
    dispatch(markReadAllRequest());
  };

  const handleClickItem = (data: NotificationItem) => {
    dispatch(markReadItemRequest(data));
  };

  const notiProps: NotificationProps = {
    items: list as NotificationItem[],
    isLoading: loading,
    totalItems: total,
    height: 300,
    isShowDot: isShowDot,
    titleText: t('notification:notificationTitleText'),
    markReadText: t('notification:notificationMarkReadText'),
    loadingText: t('notification:notificationLoadingText'),
    loadedText: t('notification:notificationLoadedText'),
    noDataText: t('notification:notificationNoDataText'),
    handleLoadMore: loadMoreNotification,
    handleOpenNoti: loadDataNotification,
    handleClickMarkRead: markReadAll,
    handleClickItem: handleClickItem,
  };

  // End Notification -----------------------

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routes = [] as any;
  // add translation for menu
  indexRoutes.forEach((itemRoute) => {
    const itemRouteCustom = { ...itemRoute };
    if (itemRoute.name) {
      itemRouteCustom.name = t(itemRoute.name);
    }
    if (itemRoute.routes) {
      itemRoute.routes.forEach((itemSubRoute, index) => {
        if (itemSubRoute.name) {
          _set(itemRouteCustom, `routes.${index}.name`, t(itemSubRoute.name));
        }
      });
    }
    routes.push(itemRouteCustom);
  });

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({});
      return token;
    } catch (e) {
      /*
       * Handle force login again after revoke token, such as after change password.
       * If use `getAccessTokenWithPopup`, user can close the popup, leading to confusing behavior.
       * Therefore, opt to use `loginWithRedirect` instead.
       */
      if (e.error === 'login_required') {
        alert(t('loginRequired'));
      } else if (e.error === 'invalid_grant' && !window.sessionExpiredAlert) {
        window.sessionExpiredAlert = true;
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
        });
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isChangePassword = Helper.getChangePassword();
      if (isChangePassword) {
        getToken();
      }
    }, GET_ACCESS_TOKEN_MINUTE_MS);

    return () => clearInterval(interval);
  });

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
    Helper.removeChangePassword();
  };

  const handleChangePassword = () => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          title: t('changePasswordModal:title'),
          content: t('changePasswordModal:content'),
          onOk: () => {
            dispatch(
              changePasswordRequest({
                client_id: AUTH0_CLIENT_ID,
                email: user?.email,
                connection: AUTH0_CONNECTION,
              }),
            );
            Helper.setChangePassword();
          },
        },
      }),
    );
  };

  const handleChangeLang = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };

  const handleClickLeftMenuItem = (pathValue) => {
    history.push(pathValue);
  };

  const menuHeader = [
    {
      key: 'LOGOUT',
      label: t('common:logoutText'),
      func: handleLogout,
    },
    {
      key: 'CHANGE_PASSWORD',
      label: t('common:changePasswordText'),
      func: handleChangePassword,
    },
  ];

  return (
    <LayoutPage
      currentUser={{
        picture: _get(user, 'picture', ''),
        name: _get(user, 'name', ''),
      }}
      pathname={location.pathname}
      route={{
        path: '/',
        routes: routes.filter((route: Route) => route.menu),
      }}
      lang={lang.current}
      langOptions={langOptions}
      logo={
        <a href="/">
          <img src="/icon.svg" alt="" />
        </a>
      }
      handleChangeLang={handleChangeLang}
      handleClickLeftMenuItem={handleClickLeftMenuItem}
      isShowNotification={true}
      notiProps={notiProps}
      menuHeader={menuHeader}
    >
      {children}
    </LayoutPage>
  );
};
export default LayoutContainer;
