import { lazy, FunctionComponent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Plugins from 'plugins';
// import Extensions from 'extensions';
import srcRoutes from 'routes';

const NotFoundPage = lazy(() => import('core/containers/NotFoundPage'));
// PLOP: Add pages import below -- DO NOT ERASE

const NotFoundRedirect = (): JSX.Element => <Redirect to="/404-not-found" />;

const RedirectToHome = (): JSX.Element => <Redirect to="/home" />;

const originalRoutes = [
  { path: '/404-not-found', component: NotFoundPage, exact: true },
  ...srcRoutes,
];

// let extendedRoutes = [];
// for (const pluginRouteIndex in Plugins.routes) {
//   if (!_isEmpty(Extensions.routes[pluginRouteIndex])) {
//     extendedRoutes = extendedRoutes.concat(Extensions.routes[pluginRouteIndex]);
//   } else {
//     extendedRoutes = extendedRoutes.concat(Plugins.routes[pluginRouteIndex]);
//   }
// }
let extendedRoutes = [];
for (const pluginRouteIndex in Plugins.routes) {
  extendedRoutes = extendedRoutes.concat(Plugins.routes[pluginRouteIndex]);
}

interface routeTypes {
  path: string;
  component: FunctionComponent<RouteComponentProps>;
  requireLogin?: boolean | undefined;
  exact?: boolean;
  menu?: boolean | undefined;
  name?: string | undefined;
  icon?: React.ReactNode;
  routes?:
    | Array<{
        path: string;
        name: string;
        icon: React.ReactNode;
      }>
    | undefined;
}
const indexRoutes: Array<routeTypes> = [
  ...originalRoutes,
  ...extendedRoutes,
  {
    path: '/',
    component: RedirectToHome,
    requireLogin: true,
    exact: true,
  },
  { path: '*', component: NotFoundRedirect },
];
export default indexRoutes;
