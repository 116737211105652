import { AxiosResponse } from 'axios';
import { SIGNED_URL_FOR_UPLOADING_S3 } from 'core/utils/config';
import apiInstance from 'core/utils/api';
export default class FileService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  upload = (payload: any): Promise<AxiosResponse> => {
    return this.post('/upload/uploadImage', payload);
  };

  getSignedUrlForUploadingS3 = async (
    file: { name: string; type: string },
    isPublic?: boolean,
  ): Promise<{ url: string; key: string }> => {
    if (file) {
      const res = await this.get(SIGNED_URL_FOR_UPLOADING_S3, {
        params: {
          fileName: file.name,
          contentType: file.type,
          isPublic: isPublic === undefined ? 'true' : isPublic + '',
        },
      });
      return res.data;
    }
    throw new Error('no file');
  };

  uploadFile = (
    file: File,
    options?: {
      isPublic?: boolean;
      onSuccess?: (key: string, req: XMLHttpRequest) => void;
      onError?: (error: Error) => void;
      onProgress?: (progress: { loaded: number; total: number; percent: number }) => void;
    },
  ): Promise<string> => {
    const { isPublic, onSuccess, onError, onProgress } = options || {};
    const req = new XMLHttpRequest();
    return this.getSignedUrlForUploadingS3(file, isPublic)
      .then(({ url, key }) => {
        return new Promise((resolve, reject) => {
          req.open('PUT', url);
          req.onload = () => {
            if (req.status >= 200 && req.status < 300) {
              resolve(key);
            } else {
              reject(new Error(req.statusText));
            }
          };
          req.onerror = () => {
            reject(new Error(req.statusText));
          };
          req.onprogress = (progress) => {
            if (onProgress) {
              onProgress({
                loaded: progress.loaded,
                total: progress.total,
                percent: 100 * (progress.loaded / progress.total),
              });
            }
          };
          req.send(file);
        });
      })
      .then(
        (key: string) => {
          if (onSuccess) onSuccess(key, req);
          return key;
        },
        (error) => {
          if (onError) onError(error);
          throw error;
        },
      );
  };
}
