import { AxiosResponse } from 'axios';
import { AUTH0_DOMAIN } from 'core/utils/config';
import apiInstance from 'core/utils/api';

export default class UserService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  changePassword = (payload: any): Promise<AxiosResponse> => {
    return this.post(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, payload);
  };
}
