import { lazy, FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PieChartOutlined,
  BarChartOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ 'containers/Dashboard'));
const SalePage = lazy(() => import(/* webpackChunkName: "SalePage" */ 'containers/SalePage'));
const SupportPage = lazy(
  () => import(/* webpackChunkName: "SupportPage" */ 'containers/SupportPage'),
);
const UserReportPage = lazy(
  () => import(/* webpackChunkName: "UserReportPage" */ 'containers/UserReportPage'),
);
const SettingPage = lazy(() => import('containers/SettingPage'));

interface routeTypes {
  path: string;
  component: FunctionComponent<RouteComponentProps>;
  requireLogin?: boolean | undefined;
  exact?: boolean;
  menu?: boolean | undefined;
  name?: string | undefined;
  icon?: React.ReactNode;
  routes?:
    | Array<{
        path: string;
        name: string;
        icon: React.ReactNode;
      }>
    | undefined;
}

const routes: Array<routeTypes> = [
  {
    path: '/home',
    component: Dashboard,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:dashboard',
    icon: <PieChartOutlined />,
  },
  {
    path: '/sales',
    component: SalePage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:sales',
    icon: <BarChartOutlined />,
  },
  {
    path: '/support',
    component: SupportPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:support',
    icon: <CustomerServiceOutlined />,
  },
  {
    path: '/user-report',
    component: UserReportPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:userReport',
    icon: <FileTextOutlined />,
  },
  {
    path: '/settings',
    component: SettingPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:settings',
    icon: <SettingOutlined />,
  },
  // PLOP: Add routes import below -- DO NOT ERASE
];

export default routes;
