/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { API_URL, LANGUAGE_DISPLAY } from './config';
import Helper from './helpers';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const authToken = _get(config, 'token');

  const requestConfig = {
    ...config,
    // Parse server
    // 'X-Parse-Application-Id': APP_ID,
    // 'X-Parse-REST-API-Key': REST_KEY,
    // ...(authToken ? { 'X-Parse-Session-Token': authToken } : {}),
  };
  // Adonis
  if (authToken) {
    requestConfig.headers.Authorization = `Bearer ${authToken}`;
  }
  requestConfig.headers['Accept-Language'] = Helper.getLang() || LANGUAGE_DISPLAY;
  return requestConfig;
});

api.interceptors.response.use(
  (response) => response.data,
  ({ message, response }) => {
    if (response?.data) {
      return Promise.reject({
        message: response.data.error || response.data.message || message,
        code: response.data.code ?? -1,
      });
    }
    return Promise.reject({ message, code: response?.status ?? -1 });
  },
);

class apiInstance {
  constructor(protected readonly apiUrl: string) {}

  get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return api.get(`${Helper.getUrl(url, this.apiUrl)}`, config).then((response) => response);
  }

  post(url: string, payload: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return api
      .post(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  put(url: string, payload: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return api
      .put(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  patch(url: string, payload: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return api
      .patch(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return api.delete(`${Helper.getUrl(url, this.apiUrl)}`, config).then((response) => response);
  }
}

export default apiInstance;
