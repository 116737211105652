import { useEffect, Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import en from 'antd/lib/locale/en_US';
import jp from 'antd/lib/locale/ja_JP';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { LoadingIndicator, ErrorBoundary } from 'admin-console-library';
import { indexRoutes, PublicRoutes, ProtectedRoute } from 'core/routes';
import ConfirmModal from 'core/components/ConfirmModal';
import DashboardPage from 'containers/Layout';
import 'core/styles/global.less';
import 'styles/global.less';
import LoginPage from 'containers/LoginPage';
import { useAppSelector } from 'store';
import 'moment/locale/ja';
import { LAGUANGE_LIST } from 'core/utils/constants';

LAGUANGE_LIST.forEach((item) => {
  moment.locale(item, {
    week: {
      dow: 8,
    },
  });
  moment.locale(item);
});

const intlMap = {
  en,
  jp,
};
const App = (): JSX.Element => {
  const lang = useAppSelector((state) => state.lang);
  const location = useLocation();
  const { search, pathname } = location;
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <div className="app-container">
      <ConfirmModal />
      <ConfigProvider locale={intlMap[lang.current]} autoInsertSpaceInButton={false}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <DashboardPage path="">
                <Spin size="large" />
              </DashboardPage>
            }
          >
            <Switch>
              {indexRoutes.map((route) => {
                if (!route.requireLogin) {
                  return (
                    <PublicRoutes
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  );
                }
                return (
                  <ProtectedRoute
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    key={route.path}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </ErrorBoundary>
        <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
      </ConfigProvider>
    </div>
  );
};

export default App;
