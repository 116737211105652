import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import App from 'containers/App';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE } from 'core/utils/config';
import { store, history } from 'store';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';

const onRedirectCallback = (appState: { returnTo: string }) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    useRefreshTokens
    cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallback}
    audience={AUTH0_AUDIENCE}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
