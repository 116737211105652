import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'store';
import { setConfirmModal, resetConfirmModal } from 'providers/GeneralProvider/slice';
import i18n from 'i18n';

const ConfirmModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state) => state.general.confirmModal);
  const { visible, data = {} } = modalData;
  const {
    title,
    okText = i18n.t('global:yes'),
    cancelText = i18n.t('global:no'),
    onOk,
    onCancel,
    closeAfterConfirm = true,
    content,
    ...props
  } = data;

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(setConfirmModal({ visible: false }));
  };

  const handleConfirm = () => {
    if (typeof onOk === 'function') {
      onOk();
    }
    if (closeAfterConfirm) {
      // Close popup first
      dispatch(setConfirmModal({ visible: false }));
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      afterClose={() => dispatch(resetConfirmModal())} // Remove popup data when popup is close
      onCancel={handleCancel}
      onOk={handleConfirm}
      cancelText={cancelText}
      okText={okText}
      centered
      zIndex={100}
      {...props}
    >
      {content && <div>{content}</div>}
    </Modal>
  );
};

export default ConfirmModal;
