import { Button, Dropdown, Row, Space, Image, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { DownOutlined } from '@ant-design/icons';
import Logo from 'assets/images/logo.svg';
import Helper from 'core/utils/helpers';
import './styles.less';
import { useAppDispatch, useAppSelector } from 'store';
import { changeLanguage } from 'core/providers/LangProvider/slice';
import i18n from 'i18n';
import { langOptions } from 'core/utils/constants';

const LoginPage = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation('login');
  const lang = useAppSelector((state) => state.lang);
  const dispatch = useAppDispatch();

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      redirectUri: `${window.location.origin}`,
    });
    Helper.getChangePassword();
  };
  const handleChangeLanguage = (langValue: string) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };
  const getLanguageImage = (langValue: string) => {
    const langItem = langOptions.find((lang) => lang.value === langValue);
    return langItem ? langItem.image : '';
  };

  const menu = (
    <Menu onClick={(e) => handleChangeLanguage(e.key)}>
      {langOptions.map((item) => (
        <Menu.Item key={item.value}>
          <Space>
            <Image width={20} preview={false} src={item.image} /> <span>{item.label}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="login-page-wrapper">
      <Dropdown overlay={menu} trigger={['click']} className="box-language">
        <Space className="pointer">
          <Image width={20} preview={false} src={getLanguageImage(lang.current)} />
          <DownOutlined style={{ fontSize: '10px', color: '#000' }} />
        </Space>
      </Dropdown>
      <div className="login-form-container">
        <Row className="logo-wrapper" justify="center">
          <img className="logo" src={Logo} alt="logo" />
        </Row>
        <div className="login-form">
          <Button size="large" className="login-btn" onClick={handleLogin}>
            {t('login')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
